// stores/auth.js
import { writable, derived } from 'svelte/store';
import { authService } from '../services/api';

function createAuthStore() {
  const initialState = {
    isAuthenticated: false,
    user: null,
    isLoading: false,
    error: null,
    initialized: false
  };

  const { subscribe, set, update } = writable(initialState);

  // Initialize store with localStorage state
  function init() {
    const authState = authService.checkAuth();
    update(state => ({
      ...state,
      isAuthenticated: authState.isAuthenticated,
      user: authState.username ? { username: authState.username } : null,
      initialized: true
    }));
  }

  // Listen for auth changes
  let unsubscribe;
  if (typeof window !== 'undefined') {
    unsubscribe = authService.onAuthChange((isAuthenticated) => {
      update(state => ({
        ...state,
        isAuthenticated,
        user: isAuthenticated ? { 
          username: localStorage.getItem('username') 
        } : null
      }));
    });
  }

  return {
    subscribe,
    
    async login(username, password) {
      if (!username?.trim() || !password?.trim()) {
        throw new Error('Username and password are required');
      }

      update(state => ({ ...state, isLoading: true, error: null }));
      
      try {
        const response = await authService.login(username, password);
        update(state => ({
          ...state,
          isAuthenticated: true,
          user: { username, ...response },
          isLoading: false
        }));
        return response;
      } catch (error) {
        update(state => ({
          ...state,
          isAuthenticated: false,
          user: null,
          error: error.message,
          isLoading: false
        }));
        throw error;
      }
    },

    async register(username, password) {
      if (!username?.trim() || !password?.trim()) {
        throw new Error('Username and password are required');
      }

      update(state => ({ ...state, isLoading: true, error: null }));
      
      try {
        const response = await authService.register(username, password);
        update(state => ({
          ...state,
          isAuthenticated: true,
          user: { username, ...response },
          isLoading: false
        }));
        return response;
      } catch (error) {
        update(state => ({
          ...state,
          error: error.message,
          isLoading: false
        }));
        throw error;
      }
    },

    async logout() {
      update(state => ({ ...state, isLoading: true, error: null }));
      
      try {
        await authService.logout();
        set({
          ...initialState,
          initialized: true
        });
      } catch (error) {
        update(state => ({
          ...state,
          error: error.message,
          isLoading: false
        }));
        throw error;
      }
    },

    checkAuth() {
      const authState = authService.checkAuth();
      update(state => ({
        ...state,
        isAuthenticated: authState.isAuthenticated,
        user: authState.username ? { username: authState.username } : null,
        initialized: true
      }));
      return authState;
    },

    clearError() {
      update(state => ({ ...state, error: null }));
    },

    reset() {
      set({
        ...initialState,
        initialized: true
      });
    },

    // Initialize the store
    init
  };
}

export const auth = createAuthStore();

// Initialize auth store
if (typeof window !== 'undefined') {
  auth.init();
}