<script>
  import { onMount, createEventDispatcher } from 'svelte';
  import { fade, slide } from 'svelte/transition';
  import { workOrderStore } from '../../stores/work-order';
  import { projectStore } from '../../stores/project';
  import { worker } from '../../stores/worker';
  import WorkOrderForm from './WorkOrderForm.svelte';

  const dispatch = createEventDispatcher();

  let showForm = false;
  let isEdit = false;
  let editingWorkOrder = null;

  let searchTerm = '';
  let statusFilter = 'ALL';
  let priorityFilter = 'ALL';

  // Debounce helper for search filtering.
  let debounceTimeout;
  function debounce(fn, delay = 300) {
    return (...args) => {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(() => fn(...args), delay);
    };
  }
  const updateSearch = debounce(() => {
    // Reactivity is triggered via searchTerm change.
  }, 300);

  // Helper functions to derive additional data.
  function getProject(wo) {
    return $projectStore.items.find(p => p.id === wo.project);
  }
  function getAssignedWorkers(wo) {
    return (wo.assigned_workers?.map(aw => $worker.workers.find(wx => wx.id === aw.worker_id)) || []).filter(Boolean);
  }

  // Derived filtering of work orders.
  $: filteredWorkOrders = ($workOrderStore.items || [])
    .filter(wo => statusFilter === 'ALL' || wo.status === statusFilter)
    .filter(wo => priorityFilter === 'ALL' || wo.priority === priorityFilter)
    .filter(wo => {
      if (!searchTerm) return true;
      const s = searchTerm.toLowerCase();
      return (
        wo.title.toLowerCase().includes(s) ||
        (wo.description && wo.description.toLowerCase().includes(s))
      );
    });

  onMount(async () => {
    await Promise.all([
      workOrderStore.load(),
      projectStore.load(),
      worker.loadWorkers()
    ]);
  });

  // Open form for new work order.
  function openCreateForm() {
    isEdit = false;
    editingWorkOrder = null;
    showForm = true;
  }

  // Open form to edit an existing work order.
  function openEditForm(wo) {
    isEdit = true;
    // Create a shallow copy of the work order.
    editingWorkOrder = JSON.parse(JSON.stringify(wo));
    showForm = true;
  }

  function handleWorkOrderSaved(event) {
    // Close the form and signal that a work order was created/updated.
    showForm = false;
    dispatch('workOrderCreated', event.detail);
  }

  async function completeWorkOrder(id) {
    try {
      await workOrderStore.update(id, { status: 'COMPLETED' });
      await projectStore.load();
    } catch (err) {
      console.error('Error completing work order:', err);
    }
  }

  async function deleteWorkOrder(id, title) {
    if (!confirm(`Are you sure you want to delete "${title}"?`)) return;
    try {
      await workOrderStore.delete(id);
      await projectStore.load();
    } catch (err) {
      console.error('Error deleting work order:', err);
    }
  }

  function getStatusBadgeClass(status) {
    switch (status) {
      case 'DRAFT':       return 'draft';
      case 'IN_PROGRESS': return 'in-progress';
      case 'ON_HOLD':     return 'on-hold';
      case 'COMPLETED':   return 'completed';
      case 'CANCELLED':   return 'cancelled';
      case 'PENDING':     return 'pending';
      case 'SCHEDULED':   return 'scheduled';
      default:            return 'default';
    }
  }

  function getPriorityBadgeClass(priority) {
    switch (priority) {
      case 'LOW':     return 'low';
      case 'MEDIUM':  return 'medium';
      case 'HIGH':    return 'high';
      case 'URGENT':  return 'urgent';
      default:        return 'medium';
    }
  }

  function formatDate(date) {
    if (!date) return '';
    return new Date(date).toLocaleString();
  }
</script>

<div class="work-order-list">
  <!-- Header -->
  <div class="list-header">
    <h2>Work Orders</h2>
    <button class="new-button" on:click={openCreateForm}>+ New Work Order</button>
  </div>

  {#if showForm}
    <!-- Modal for WorkOrderForm -->
    <div class="modal-overlay">
      <div class="modal-container">
        <WorkOrderForm
          isEdit={isEdit}
          existingWorkOrder={editingWorkOrder}
          on:close={() => (showForm = false)}
          on:saved={handleWorkOrderSaved}
        />
      </div>
    </div>
  {:else}
    <!-- Filters -->
    <div class="filters">
      <input
        type="text"
        placeholder="Search work orders..."
        bind:value={searchTerm}
        on:input={updateSearch}
        class="search-input"
        aria-label="Search Work Orders"
      />
      <div class="filter-group">
        <select bind:value={statusFilter} class="filter-select" aria-label="Filter by status">
          <option value="ALL">All Statuses</option>
          <option value="DRAFT">Draft</option>
          <option value="PENDING">Pending</option>
          <option value="SCHEDULED">Scheduled</option>
          <option value="IN_PROGRESS">In Progress</option>
          <option value="ON_HOLD">On Hold</option>
          <option value="COMPLETED">Completed</option>
          <option value="CANCELLED">Cancelled</option>
        </select>
        <select bind:value={priorityFilter} class="filter-select" aria-label="Filter by priority">
          <option value="ALL">All Priorities</option>
          <option value="LOW">Low</option>
          <option value="MEDIUM">Medium</option>
          <option value="HIGH">High</option>
          <option value="URGENT">Urgent</option>
        </select>
      </div>
    </div>

    {#if $workOrderStore.loading}
      <div class="loading">Loading work orders...</div>
    {:else if $workOrderStore.error}
      <div class="error-message">{$workOrderStore.error}</div>
    {:else if filteredWorkOrders.length === 0}
      <div class="empty-message">
        {searchTerm || statusFilter !== 'ALL' || priorityFilter !== 'ALL'
          ? 'No work orders found'
          : 'No work orders yet'}
      </div>
    {:else}
      <ul class="work-order-items">
        {#each filteredWorkOrders as wo (wo.id)}
          <li class="work-order-item">
            {#if getProject(wo)}
              <div class="wo-project-banner">
                <span class="project-badge">Project: {getProject(wo).title}</span>
              </div>
            {/if}
            <div class="wo-header">
              <h3 class="wo-title">{wo.title}</h3>
              <span class="status-badge {getStatusBadgeClass(wo.status)}">
                {wo.status}
              </span>
            </div>
            <div class="wo-meta">
              <span class="priority-badge {getPriorityBadgeClass(wo.priority)}">
                {wo.priority}
              </span>
              {#if wo.status === 'IN_PROGRESS'}
                <button class="complete-btn" on:click={() => completeWorkOrder(wo.id)}>
                  Complete
                </button>
              {/if}
            </div>
            {#if wo.description}
              <p class="wo-description">{wo.description}</p>
            {/if}
            {#if getAssignedWorkers(wo).length > 0}
              <div class="assigned-workers">
                <span class="detail-label">Assigned:</span>
                <div class="worker-tags">
                  {#each getAssignedWorkers(wo) as w}
                    <span class="worker-tag">{w.name}</span>
                  {/each}
                </div>
              </div>
            {/if}
            <div class="wo-actions">
              <button class="edit-btn" on:click={() => openEditForm(wo)}>Edit</button>
              <button class="delete-btn" on:click={() => deleteWorkOrder(wo.id, wo.title)}>
                Delete
              </button>
            </div>
          </li>
        {/each}
      </ul>
    {/if}
  {/if}
</div>

<style>
  /* Centralized Theme Variables */
  :root {
    --primary-color: #2563eb;
    --primary-dark: #1d4ed8;
    --background-color: #f8fafc;
    --text-color: #111827;
    --border-color: #e5e7eb;
    --error-color: #dc2626;
    --font-family: system-ui, sans-serif;
  }

  .work-order-list {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    font-family: var(--font-family);
    background: white;
  }

  /* List Header */
  .list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  .list-header h2 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--text-color);
  }
  .new-button {
    padding: 0.5rem 1rem;
    background: #2196F3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.2s;
  }
  .new-button:hover {
    background: #1976D2;
  }

  /* Modal Styles */
  .modal-overlay {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  .modal-container {
    background: #fff;
    border-radius: 1rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    max-width: 90%;
    width: 500px;
    max-height: 90vh;
    overflow-y: auto;
    padding: 1rem;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  @media (max-width: 640px) {
    .modal-container {
      width: 95%;
      height: 90vh;
      border-radius: 0;
      margin: 0;
    }
  }

  /* Filters */
  .filters {
    display: grid;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  .search-input,
  .filter-select {
    padding: 0.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    font-size: 0.875rem;
  }
  .filter-group {
    display: flex;
    gap: 1rem;
  }

  /* Work Order Items */
  .work-order-items {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    gap: 1rem;
  }
  .work-order-item {
    padding: 1.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    background: white;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  .work-order-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  }
  .wo-project-banner {
    background: #f3f4f6;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-size: 0.875rem;
    color: #374151;
  }
  .project-badge {
    font-weight: 500;
  }
  .wo-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }
  .wo-title {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--text-color);
  }
  .status-badge {
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;
  }
  /* Status Badge Variants */
  .status-badge.draft        { background: #f3f4f6; color: #4b5563; }
  .status-badge.in-progress  { background: #dcfce7; color: #166534; }
  .status-badge.on-hold      { background: #fef3c7; color: #92400e; }
  .status-badge.completed    { background: #e5e7eb; color: #6b7280; }
  .status-badge.cancelled    { background: #fee2e2; color: #dc2626; }
  .status-badge.pending      { background: #dbeafe; color: #1e40af; }
  .status-badge.scheduled    { background: #e9d5ff; color: #6d28d9; }

  .wo-meta {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .priority-badge {
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;
  }
  /* Priority Badge Variants */
  .priority-badge.low      { background: #f3f4f6; color: #4b5563; }
  .priority-badge.medium   { background: #dbeafe; color: #1e40af; }
  .priority-badge.high     { background: #fef3c7; color: #92400e; }
  .priority-badge.urgent   { background: #fee2e2; color: #dc2626; }

  .wo-description {
    margin: 0;
    color: #4b5563;
    font-size: 0.875rem;
  }

  .assigned-workers {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 0.875rem;
  }
  .worker-tags {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
  }
  .worker-tag {
    background: #f3f4f6;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.75rem;
  }

  .wo-actions {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
  }
  .edit-btn {
    background: #06b6d4;
    color: white;
    border: none;
    padding: 0.25rem 0.75rem;
    border-radius: 4px;
    font-size: 0.75rem;
    cursor: pointer;
    transition: background 0.2s;
  }
  .edit-btn:hover {
    background: #0891b2;
  }
  .delete-btn {
    background: #dc2626;
    color: white;
    border: none;
    padding: 0.25rem 0.75rem;
    border-radius: 4px;
    font-size: 0.75rem;
    cursor: pointer;
    transition: background 0.2s;
  }
  .delete-btn:hover {
    background: #b91c1c;
  }

  .loading,
  .empty-message,
  .error-message {
    text-align: center;
    padding: 2rem;
    color: #6b7280;
  }
  .error-message {
    color: var(--error-color);
    background: #fee2e2;
    border-radius: 4px;
  }
</style>
