console.log('API Base URL:', import.meta.env.VITE_API_URL);

export const API_CONFIG = {
    BASE_URL: import.meta.env.VITE_API_URL || '',
    ENDPOINTS: {
        AUTH: {
            LOGIN: 'accounts/login/',         // Changed back to login
            REGISTER: 'accounts/register/',
            REFRESH: 'accounts/token/refresh/',
            LOGOUT: 'accounts/logout/',
            CHECK_AUTH: 'accounts/check-auth/'
        },
        PORTFOLIO: {
            LIST: '/api/portfolio/',
            CREATE: '/api/works/',
            UPDATE: (id) => `/api/works/${id}/`,
            DELETE: (id) => `/api/works/${id}/`,
            UPLOAD: '/api/works/'
        },
        WORKER: {
            BASE: '/api/workers/',
            LIST: '/api/workers/',
            CREATE: '/api/workers/',
            UPDATE: (id) => `/api/workers/${id}/`,
            DELETE: (id) => `/api/workers/${id}/`,
            PROJECTS: (id) => `/api/workers/${id}/projects/`,
            SCHEDULE: (id) => `/api/workers/${id}/schedule/`,
            WORK_ORDERS: (id) => `/api/workers/${id}/current-work-orders/`
        },
        CLIENT: {
            BASE: '/api/clients/',
            LIST: '/api/clients/',
            CREATE: '/api/clients/',
            UPDATE: (id) => `/api/clients/${id}/`,
            DELETE: (id) => `/api/clients/${id}/`
        },
        PROJECT: {
            BASE: '/api/projects/',
            LIST: '/api/projects/',
            CREATE: '/api/projects/',
            UPDATE: (id) => `/api/projects/${id}/`,
            DELETE: (id) => `/api/projects/${id}/`,
            WORKS: (id) => `/api/projects/${id}/works/`,
            WORK_ORDERS: (id) => `/api/projects/${id}/work-orders/`,
            ADD_WORKER: (id) => `/api/projects/${id}/add_worker/`
        },
        WORK_ORDER: {
            BASE: '/api/work-orders/',
            LIST: '/api/work-orders/',
            CREATE: '/api/work-orders/',
            UPDATE: (id) => `/api/work-orders/${id}/`,
            DELETE: (id) => `/api/work-orders/${id}/`,
            ASSIGN_WORKER: (id) => `/api/work-orders/${id}/assign_worker/`,
            ADD_UPDATE: (id) => `/api/work-orders/${id}/add_update/`,
            START: (id) => `/api/work-orders/${id}/start_work/`,
            COMPLETE: (id) => `/api/work-orders/${id}/complete_work/`
        },
        USER_SETTINGS: {
            PREFERENCES: '/user-settings/preferences/',
            CAPABILITIES: '/user-settings/capabilities/'
        },
        VISION: {
            ANALYZE_IMAGE: '/api/vision/analyze-image/'
        },
        DESCRIPTION: {
            GENERATE: '/api/description/generate/',
            REGENERATE: '/api/description/regenerate/'
        },
        COMMUNICATIONS: {
            LIST: '/api/communications/channels/',
            CREATE: '/api/communications/channels/',
            TEMPLATES: '/api/communications/templates/',
            CREATE_TEMPLATE: '/api/communications/templates/',
            MESSAGES: '/api/communications/messages/',
            SEND_INVITATION: '/api/communications/messages/send_invitation/',
            TRACK_EVENT: (id) => `/api/communications/messages/${id}/track_event/`
        },
        PORTALS: {
            USER: '/api/portals/users/me/',
            DASHBOARD: '/api/portals/dashboards/me/',
            UPDATE_LAYOUT: (id) => `/api/portals/dashboards/${id}/update_layout/`,
            WIDGET_DATA: (id) => `/api/portals/widgets/${id}/data/`,
            ACTIVITY: '/api/portals/activity/'
        }
    }
};

// Set default BASE_URL if not provided
if (!API_CONFIG.BASE_URL && typeof window !== 'undefined') {
    API_CONFIG.BASE_URL = window.location.origin;
}

/**
 * Utility function to get a full URL for an endpoint
 * Cleans up any potential double slashes and handles dynamic endpoints
 */
export const getFullUrl = (endpoint) => {
    if (!endpoint) {
        throw new Error('Endpoint is required');
    }

    const baseUrl = API_CONFIG.BASE_URL.endsWith('/')
        ? API_CONFIG.BASE_URL.slice(0, -1)
        : API_CONFIG.BASE_URL;
    
    const cleanEndpoint = typeof endpoint === 'function' 
        ? endpoint()
        : endpoint;

    // Remove leading slash if exists
    const normalizedEndpoint = cleanEndpoint.startsWith('/')
        ? cleanEndpoint.slice(1)
        : cleanEndpoint;
    
    return `${baseUrl}/${normalizedEndpoint}`;
};

// Utility function to build query parameters
export const buildQueryString = (params = {}) => {
    const query = Object.entries(params)
        .filter(([_, value]) => value !== undefined && value !== null)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');
    
    return query ? `?${query}` : '';
};

// Export services
export { apiClient } from './client';
export { authService } from './auth';
export { portfolioService } from './portfolio';
export { clientService } from './client-service';
export { projectService } from './project-service';
export { userSettingsService } from './userSettings';
export * from './vision';