// stores/sessionManager.js
import { writable } from 'svelte/store';
import { auth } from './auth';

function createSessionManager() {
  // We've simplified the state to just what we need
  const initialState = {
    showSessionModal: false,
    lastError: null,
    isInitialized: false
  };

  const { subscribe, set, update } = writable(initialState);
  let store;

  // Keep logging for debugging purposes
  subscribe(value => {
    store = value;
    console.log('Session manager state:', value);
  });

  return {
    subscribe,

    // Simplified session expiration handler - no more promises or timeouts
    handleSessionExpired(error) {
      console.log('Session expired called:', error);

      if (!store?.isInitialized) {
        console.error('Session manager not initialized');
        throw new Error('Session manager not initialized');
      }

      // Clear any existing auth state
      auth.reset();
      
      // Show the modal immediately
      update(state => ({
        ...state,
        showSessionModal: true,
        lastError: error
      }));
    },

    // Simplified close handler - no navigation needed since we're reloading
    closeSessionModal() {
      console.log('Closing session modal');
      update(state => ({
        ...state,
        showSessionModal: false,
        lastError: null
      }));
    },

    // Reset to initial state
    reset() {
      console.log('Resetting session manager');
      set({
        ...initialState,
        isInitialized: true
      });
    },

    // Initialize the manager
    init() {
      console.log('Initializing session manager');
      update(state => ({
        ...state,
        isInitialized: true
      }));
    }
  };
}

export const sessionManager = createSessionManager();

// Initialize if we're in the browser
if (typeof window !== 'undefined') {
  sessionManager.init();
}