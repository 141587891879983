<!-- src/components/common/SessionModal.svelte -->
<script>
  import { fade } from 'svelte/transition';
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();
  
  // Using a more direct, actionable message for the user
  export let message = 'Please reload this page to log back in.';

  function handleReload() {
    // Force a clean page reload to ensure fresh state
    window.location.reload();
  }
</script>

<div 
  class="modal-overlay" 
  transition:fade={{ duration: 150 }}
>
  <div class="modal-content">
    <div class="modal-header">
      <h2>Session Expired</h2>
    </div>
    <div class="modal-body">
      <p>{message}</p>
      <button class="reload-button" on:click={handleReload}>
        Reload Page
      </button>
    </div>
  </div>
</div>

<style>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    padding: 1rem;
  }

  .modal-content {
    background: white;
    border-radius: 0.75rem;
    padding: 1.5rem;
    width: 90%;
    max-width: 360px;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  .modal-header {
    margin-bottom: 1rem;
  }

  h2 {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 600;
    color: #1a202c;
  }

  p {
    margin: 0 0 1.5rem;
    color: #4a5568;
    line-height: 1.5;
  }

  .reload-button {
    background: #3182ce;
    color: white;
    font-weight: 500;
    padding: 0.75rem 2rem;
    border: none;
    border-radius: 0.5rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s, transform 0.1s;
    width: 100%;
    max-width: 200px;
  }

  .reload-button:hover {
    background: #2c5282;
    transform: translateY(-1px);
  }

  .reload-button:active {
    transform: translateY(0);
  }

  /* Mobile responsiveness */
  @media (max-width: 640px) {
    .modal-content {
      width: 95%;
      padding: 1.25rem;
    }

    .reload-button {
      padding: 0.875rem 1.5rem;
    }
  }
</style>