<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { clientStore } from '../../stores/client';
  import { fade, slide } from 'svelte/transition';
  import { cubicOut } from 'svelte/easing';
  const isMobile = typeof window !== 'undefined' ? window.innerWidth <= 640 : false;

  const dispatch = createEventDispatcher();

  // Validation rules – now all fields are required.
  const VALIDATION_RULES = {
    name: {
      required: true,
      minLength: 2,
      maxLength: 100
    },
    email: {
      required: true,
      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      message: 'Please enter a valid email address'
    },
    phone: {
      required: true,
      pattern: /^\(\d{3}\) \d{3}-\d{4}$/,
      message: 'Please enter a valid US phone number'
    },
    notes: {
      required: true,
      maxLength: 1000,
      message: 'Notes must be less than 1000 characters'
    }
  };

  // All fields are now required.
  let formData = {
    name: '',
    contact_email: '',
    phone_number: '',
    notes: '',
    is_active: true
  };

  // Capture the initial (pristine) state for dirty-checking.
  const initialFormData = { ...formData };

  let fieldErrors = {
    name: '',
    contact_email: '',
    phone_number: '',
    notes: ''
  };

  let isLoading = false;
  let error = null;
  let submitAttempted = false;
  let nameInput;
  let startY = 0;
  let currentY = 0;
  let isDragging = false;
  const CLOSE_THRESHOLD = 150;
  let isClosing = false;

  onMount(() => {
    nameInput?.focus();
    if (isMobile) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = '';
    };
  });

  // Check if the form is dirty by comparing current data with initial data.
  $: isFormDirty = JSON.stringify(formData) !== JSON.stringify(initialFormData);
  $: sheetStyle = isDragging ? `transform: translateY(${currentY}px)` : '';

  // Validate all fields – errors are set only if submitAttempted is true or the form is dirty.
  function validateForm() {
    // Only validate when the user has tried to submit or changes have been made.
    if (!submitAttempted && !isFormDirty) return true;

    let isFormValid = true;
    fieldErrors = { name: '', contact_email: '', phone_number: '', notes: '' };

    // Validate Name
    if (!formData.name.trim()) {
      fieldErrors.name = 'Name is required';
      isFormValid = false;
    } else if (formData.name.trim().length < VALIDATION_RULES.name.minLength) {
      fieldErrors.name = `Name must be at least ${VALIDATION_RULES.name.minLength} characters`;
      isFormValid = false;
    } else if (formData.name.trim().length > VALIDATION_RULES.name.maxLength) {
      fieldErrors.name = `Name must be less than ${VALIDATION_RULES.name.maxLength} characters`;
      isFormValid = false;
    }

    // Validate Email
    if (!formData.contact_email.trim()) {
      fieldErrors.contact_email = 'Email is required';
      isFormValid = false;
    } else if (!VALIDATION_RULES.email.pattern.test(formData.contact_email)) {
      fieldErrors.contact_email = VALIDATION_RULES.email.message;
      isFormValid = false;
    }

    // Validate Phone Number
    if (!formData.phone_number.trim()) {
      fieldErrors.phone_number = 'Phone number is required';
      isFormValid = false;
    } else if (!VALIDATION_RULES.phone.pattern.test(formData.phone_number)) {
      fieldErrors.phone_number = VALIDATION_RULES.phone.message;
      isFormValid = false;
    }

    // Validate Notes
    if (!formData.notes.trim()) {
      fieldErrors.notes = 'Notes are required';
      isFormValid = false;
    } else if (formData.notes.length > VALIDATION_RULES.notes.maxLength) {
      fieldErrors.notes = VALIDATION_RULES.notes.message;
      isFormValid = false;
    }

    return isFormValid;
  }

  $: isValid = validateForm();

  // Mobile drag-to-close handlers.
  function handleTouchStart(e) {
    if (isClosing) return;
    startY = e.touches[0].clientY;
    isDragging = true;
  }

  function handleTouchMove(e) {
    if (!isDragging || isClosing) return;
    const delta = e.touches[0].clientY - startY;
    if (delta < 0) return;
    currentY = delta;
    e.preventDefault();
  }

  async function handleTouchEnd() {
    if (!isDragging || isClosing) return;
    if (currentY > CLOSE_THRESHOLD) {
      isClosing = true;
      if (window.navigator?.vibrate) window.navigator.vibrate(50);
      handleConfirmClose();
    }
    currentY = 0;
    isDragging = false;
  }

  // Format phone number for display.
  function formatPhone(input) {
    if (!input) return '';
    const digits = input.replace(/\D/g, '');
    if (digits.length <= 3) {
      return digits;
    }
    if (digits.length <= 6) {
      return `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
    }
    return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
  }

  function handlePhoneInput(e) {
    formData.phone_number = formatPhone(e.target.value);
    if (submitAttempted) validateForm();
  }

  function handleInput() {
    if (submitAttempted) validateForm();
  }

  // Confirm close only if the form is dirty.
  function handleConfirmClose() {
    if (isLoading) return;
    if (isFormDirty) {
      if (confirm('Are you sure you want to discard your changes?')) {
        dispatch('close');
      }
    } else {
      dispatch('close');
    }
  }

  async function handleSubmit() {
    submitAttempted = true;
    if (!validateForm()) {
      error = 'Please correct the errors below';
      return;
    }
    isLoading = true;
    error = null;
    try {
      const rawDigits = formData.phone_number.replace(/\D/g, '');
      let phoneE164 = null;
      if (rawDigits.length === 10) {
        phoneE164 = `+1${rawDigits}`;
      } else if (rawDigits.length > 0) {
        phoneE164 = `+${rawDigits}`;
      }

      const clientData = {
        name: formData.name.trim(),
        contact_email: formData.contact_email.trim(),
        phone_number: phoneE164,
        notes: formData.notes.trim(),
        is_active: formData.is_active
      };

      const newClient = await clientStore.add(clientData);
      dispatch('created', { client: newClient });
    } catch (err) {
      console.error('Client creation failed:', err);
      if (err.status === 400) {
        error = 'Please check the form for errors';
        const serverErrors = err.data?.errors || {};
        Object.keys(serverErrors).forEach(field => {
          if (field in fieldErrors) {
            fieldErrors[field] = serverErrors[field][0];
          }
        });
      } else if (err.status === 401) {
        error = 'Session expired. Please log in again';
        dispatch('session-expired');
      } else if (err.status === 429) {
        error = 'Too many attempts. Please try again later';
      } else {
        error = 'Unable to create client. Please try again';
      }
    } finally {
      isLoading = false;
    }
  }
</script>

<div 
  class="modal-overlay" 
  class:mobile={isMobile}
  on:click={handleConfirmClose}
  transition:fade={{ duration: 200 }}
>
  <div
    class="modal-sheet"
    class:mobile={isMobile}
    style={sheetStyle}
    on:click|stopPropagation
    transition:slide={{ duration: 300, easing: cubicOut, axis: 'y' }}
  >
    {#if isMobile}
      <div 
        class="drag-handle"
        on:touchstart={handleTouchStart}
        on:touchmove={handleTouchMove}
        on:touchend={handleTouchEnd}
      >
        <div class="handle-bar"></div>
      </div>
    {/if}

    <div class="client-form">
      <div class="form-header">
        <button 
          type="button" 
          class="back-btn" 
          on:click={handleConfirmClose}
          disabled={isLoading}
          aria-label="Go back"
        >
          ←
        </button>
        <h2>Add New Client</h2>
        <button
          type="submit"
          class="save-btn"
          disabled={!isValid || isLoading}
          on:click={handleSubmit}
          aria-busy={isLoading}
        >
          {isLoading ? 'Adding...' : 'Save'}
        </button>
      </div>

      {#if error}
        <div class="error-message" role="alert" transition:fade>
          {error}
        </div>
      {/if}

      <form 
        on:submit|preventDefault={handleSubmit}
        class="form-content"
        novalidate
      >
        <div class="form-group">
          <label for="name">Name <span class="required">*</span></label>
          <input
            type="text"
            id="name"
            bind:this={nameInput}
            class:error={submitAttempted && fieldErrors.name}
            bind:value={formData.name}
            on:input={handleInput}
            placeholder="Enter client name"
            maxlength={VALIDATION_RULES.name.maxLength}
            aria-invalid={submitAttempted && !!fieldErrors.name}
            aria-describedby={fieldErrors.name ? 'name-error' : undefined}
            required
          />
          {#if submitAttempted && fieldErrors.name}
            <span class="field-error" id="name-error" transition:fade>
              {fieldErrors.name}
            </span>
          {/if}
        </div>

        <div class="form-group">
          <label for="phone">Phone Number</label>
          <input
            type="tel"
            id="phone"
            class:error={submitAttempted && fieldErrors.phone_number}
            bind:value={formData.phone_number}
            on:input={handlePhoneInput}
            placeholder="(555) 555-5555"
            inputmode="numeric"
            aria-invalid={submitAttempted && !!fieldErrors.phone_number}
            aria-describedby={fieldErrors.phone_number ? 'phone-error' : 'phone-help'}
          />
          <small id="phone-help" class="help-text">Enter 10-digit US phone number</small>
          {#if submitAttempted && fieldErrors.phone_number}
            <span class="field-error" id="phone-error" transition:fade>
              {fieldErrors.phone_number}
            </span>
          {/if}
        </div>

        <div class="form-group">
          <label for="email">Email</label>
          <input
            type="email"
            id="email"
            class:error={submitAttempted && fieldErrors.contact_email}
            bind:value={formData.contact_email}
            on:input={handleInput}
            placeholder="client@example.com"
            inputmode="email"
            aria-invalid={submitAttempted && !!fieldErrors.contact_email}
            aria-describedby={fieldErrors.contact_email ? 'email-error' : undefined}
            required
          />
          {#if submitAttempted && fieldErrors.contact_email}
            <span class="field-error" id="email-error" transition:fade>
              {fieldErrors.contact_email}
            </span>
          {/if}
        </div>

        <div class="form-group">
          <label for="status">Status</label>
          <div class="status-toggle">
            <label class="toggle">
              <input
                type="checkbox"
                id="status"
                bind:checked={formData.is_active}
                aria-label="Client active status"
              />
              <span class="toggle-slider"></span>
            </label>
            <span class="status-label">
              {formData.is_active ? 'Active' : 'Inactive'}
            </span>
          </div>
        </div>

        <div class="form-group">
          <label for="notes">Notes</label>
          <textarea
            id="notes"
            class:error={submitAttempted && fieldErrors.notes}
            bind:value={formData.notes}
            on:input={handleInput}
            placeholder="Add any additional information"
            rows="3"
            class="notes-input"
            maxlength="1000"
            aria-invalid={submitAttempted && !!fieldErrors.notes}
            aria-describedby={fieldErrors.notes ? 'notes-error' : undefined}
            required
          ></textarea>
          {#if submitAttempted && fieldErrors.notes}
            <span class="field-error" id="notes-error" transition:fade>
              {fieldErrors.notes}
            </span>
          {/if}
          <small class="help-text text-right">
            {formData.notes.length}/1000
          </small>
        </div>

        <p class="note">
          * All fields are required
        </p>
      </form>
    </div>
  </div>
</div>

<style>
  .modal-overlay {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
    display: grid;
    place-items: center;
    z-index: 50;
    backdrop-filter: blur(4px);
  }

  .modal-sheet {
    background: white;
    width: 100%;
    max-width: 600px;
    max-height: 90vh;
    border-radius: 1rem;
    overflow: hidden;
    position: relative;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }

  .modal-overlay.mobile {
    background: rgba(0, 0, 0, 0.25);
    align-items: flex-end;
    backdrop-filter: blur(8px);
  }

  .modal-sheet.mobile {
    max-width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    height: 85vh;
    touch-action: none;
  }

  .drag-handle {
    height: 24px;
    display: grid;
    place-items: center;
    background: white;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    touch-action: none;
  }

  .handle-bar {
    width: 32px;
    height: 4px;
    background: #e5e7eb;
    border-radius: 2px;
  }

  .client-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: white;
  }

  .form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #e5e7eb;
    position: sticky;
    top: 0;
    background: white;
    z-index: 10;
  }

  .form-header h2 {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
  }

  .back-btn,
  .save-btn {
    height: 48px;
    min-width: 48px;
    border: none;
    border-radius: 0.5rem;
    font-size: 1.125rem;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    transition: all 0.2s ease;
  }

  .back-btn {
    background: transparent;
    font-size: 1.5rem;
  }

  .back-btn:hover:not(:disabled) {
    background: #f3f4f6;
  }

  .save-btn {
    background: #2563eb;
    color: white;
  }

  .save-btn:hover:not(:disabled) {
    background: #1d4ed8;
  }

  .save-btn:disabled {
    background: #93c5fd;
    cursor: not-allowed;
  }

  .form-content {
    flex: 1;
    padding: 1rem;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .form-group {
    margin-bottom: 1.5rem;
  }

  .required {
    color: #dc2626;
  }

  input,
  .notes-input {
    width: 100%;
    padding: 0.75rem 1rem;
    border: 1px solid #d1d5db;
    border-radius: 0.5rem;
    font-size: 1rem;
    background: white;
    transition: all 0.2s ease;
  }

  input {
    height: 48px;
  }

  .notes-input {
    min-height: 96px;
    resize: vertical;
  }

  input:focus,
  .notes-input:focus {
    outline: none;
    border-color: #2563eb;
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
  }

  input.error,
  .notes-input.error {
    border-color: #dc2626;
  }

  .error-message {
    margin: 1rem;
    padding: 1rem;
    background: #fee2e2;
    color: #dc2626;
    border-radius: 0.5rem;
    font-size: 0.875rem;
  }

  .field-error {
    color: #dc2626;
    font-size: 0.75rem;
    margin-top: 0.25rem;
    display: block;
  }

  .help-text {
    font-size: 0.75rem;
    color: #6b7280;
    margin-top: 0.25rem;
  }

  .text-right {
    text-align: right;
  }

  .status-toggle {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .toggle {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 24px;
  }

  .toggle input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e5e7eb;
    transition: 0.4s;
    border-radius: 24px;
  }

  .toggle-slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .toggle-slider {
    background-color: #2563eb;
  }

  input:checked + .toggle-slider:before {
    transform: translateX(24px);
  }

  .status-label {
    font-size: 0.875rem;
    color: #6b7280;
  }

  .note {
    font-size: 0.875rem;
    color: #6b7280;
    margin-top: 2rem;
    text-align: center;
  }

  @media (max-width: 640px) {
    .form-header {
      padding: 0.75rem 1rem;
    }

    .form-content {
      padding: 1rem;
      padding-bottom: calc(env(safe-area-inset-bottom, 1rem) + 4rem);
    }

    input,
    .notes-input {
      font-size: 16px;
    }

    .back-btn,
    .save-btn {
      height: 44px;
      min-width: 44px;
      font-size: 1rem;
    }
  }
</style>
