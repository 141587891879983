// services/api/auth.js
import { API_CONFIG } from './config';
import { apiClient } from './client';

const TOKEN_KEYS = {
  ACCESS: 'accessToken',
  REFRESH: 'refreshToken',
  USERNAME: 'username'
};

const AUTH_EVENTS = {
  LOGIN: 'auth:login',
  LOGOUT: 'auth:logout',
  SESSION_EXPIRED: 'auth:session_expired'
};

export const authService = {
  _clearTokens() {
    Object.values(TOKEN_KEYS).forEach(key => localStorage.removeItem(key));
    window.dispatchEvent(new CustomEvent(AUTH_EVENTS.LOGOUT));
  },

  _setTokens(tokens, username) {
    if (tokens?.access) {
      localStorage.setItem(TOKEN_KEYS.ACCESS, tokens.access);
      if (tokens.refresh) {
        localStorage.setItem(TOKEN_KEYS.REFRESH, tokens.refresh);
      }
      if (username) {
        localStorage.setItem(TOKEN_KEYS.USERNAME, username);
      }
      window.dispatchEvent(new CustomEvent(AUTH_EVENTS.LOGIN, { 
        detail: { username } 
      }));
    }
  },

  async login(username, password) {
    try {
      const data = await apiClient.request(
        API_CONFIG.ENDPOINTS.AUTH.LOGIN,
        {
          method: 'POST',
          body: JSON.stringify({ username, password })
        }
      );

      if (!data?.access) {
        throw new Error('Invalid login response');
      }

      this._setTokens(data, username);
      return data;
    } catch (error) {
      this._clearTokens();
      throw error;
    }
  },

  async register(username, password) {
    try {
      const data = await apiClient.request(
        API_CONFIG.ENDPOINTS.AUTH.REGISTER,
        {
          method: 'POST',
          body: JSON.stringify({ username, password })
        }
      );

      if (data?.success) {
        return await this.login(username, password);
      }

      throw new Error(data?.error || 'Registration failed');
    } catch (error) {
      this._clearTokens();
      throw error;
    }
  },

  async logout() {
    try {
      const refreshToken = localStorage.getItem(TOKEN_KEYS.REFRESH);
      if (refreshToken) {
        await apiClient.request(
          API_CONFIG.ENDPOINTS.AUTH.LOGOUT,
          {
            method: 'POST',
            body: JSON.stringify({ refresh: refreshToken })
          }
        );
      }
    } finally {
      this._clearTokens();
    }
  },

  async refreshToken() {
    const refreshToken = localStorage.getItem(TOKEN_KEYS.REFRESH);
    if (!refreshToken) {
      throw new Error('No refresh token available');
    }

    try {
      const response = await fetch(
        `${API_CONFIG.BASE_URL}${API_CONFIG.ENDPOINTS.AUTH.REFRESH}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ refresh: refreshToken })
        }
      );

      if (!response.ok) {
        throw new Error('Token refresh failed');
      }

      const data = await response.json();
      if (!data?.access) {
        throw new Error('Invalid refresh response');
      }

      this._setTokens({
        access: data.access,
        refresh: refreshToken
      }, localStorage.getItem(TOKEN_KEYS.USERNAME));

      return data.access;
    } catch (error) {
      this._clearTokens();
      throw error;
    }
  },

  checkAuth() {
    const token = localStorage.getItem(TOKEN_KEYS.ACCESS);
    const username = localStorage.getItem(TOKEN_KEYS.USERNAME);
    return { 
      isAuthenticated: !!token,
      username
    };
  },

  onAuthChange(callback) {
    const handler = (event) => callback(event.type === AUTH_EVENTS.LOGIN);
    window.addEventListener(AUTH_EVENTS.LOGIN, handler);
    window.addEventListener(AUTH_EVENTS.LOGOUT, handler);
    return () => {
      window.removeEventListener(AUTH_EVENTS.LOGIN, handler);
      window.removeEventListener(AUTH_EVENTS.LOGOUT, handler);
    };
  }
};
