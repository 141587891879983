<script>
  import { Router, Link, Route } from 'svelte-routing';
  import { onMount } from 'svelte';
  import { auth } from './stores/auth';
  import { sessionManager } from './stores/sessionManager';
  import { navigate } from 'svelte-routing';
  import Portfolio from './components/portfolio/Portfolio.svelte';
  import CreateWork from './components/create/CreateWork.svelte';
  import AuthModal from './components/auth/AuthModal.svelte';
  import SessionModal from './components/common/SessionModal.svelte';

  export let url = '';
  let showAuthModal = false;
  let currentPath = window.location.pathname;
  let mounted = false;
  let unsubscribe;

  $: {
    console.log('Session modal state:', $sessionManager.showSessionModal);
  }

  onMount(() => {
    auth.checkAuth();
    mounted = true;

    unsubscribe = sessionManager.subscribe(state => {
      console.log('Session manager state updated:', state);
    });

    window.debugAuth = {
      checkState: () => {
        console.log({
          tokens: {
            access: localStorage.getItem('accessToken'),
            refresh: localStorage.getItem('refreshToken'),
            username: localStorage.getItem('username')
          },
          localStorage: {...localStorage},
          sessionState: $sessionManager
        });
      }
    };

    const handleRouteChange = () => {
      currentPath = window.location.pathname;
    };

    window.addEventListener('popstate', handleRouteChange);
    
    return () => {
      window.removeEventListener('popstate', handleRouteChange);
      if (unsubscribe) unsubscribe();
    };
  });

  function handleNavigationClick(event, path) {
    event.preventDefault();
    if (!$auth.isAuthenticated) {
      showAuthModal = true;
      return;
    }
    navigate(path);
  }

  function handleAuthSuccess() {
    showAuthModal = false;
    navigate(currentPath);
  }
</script>

{#if mounted}
  <Router {url}>
    <main class="app-container">
      <nav class="nav-buttons">
        {#if currentPath !== '/'}
          <Link to="/" class="fab create-btn" on:click={(e) => handleNavigationClick(e, '/')}>
            <!-- Icon content -->
          </Link>
        {/if}
        {#if currentPath !== '/create'}
          <Link to="/create" class="fab portfolio-btn" on:click={(e) => handleNavigationClick(e, '/create')}>
            <!-- Icon content -->
          </Link>
        {/if}
      </nav>
      <Route path="/" component={Portfolio} />
      <Route path="create" component={CreateWork} />
    </main>
  </Router>
{/if}

{#if showAuthModal}
  <AuthModal
    on:close={() => showAuthModal = false}
    on:success={handleAuthSuccess}
  />
{/if}

{#if $sessionManager.showSessionModal}
  <SessionModal
    message={$sessionManager.lastError?.message}
    on:close={() => sessionManager.closeSessionModal()}
  />
{/if}

<style>
  .app-container {
    position: relative;
    width: 100%;
    min-height: 100vh;
  }

  .nav-buttons {
    position: fixed;
    right: 30px;
    top: 30px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    z-index: 100;
  }

  .fab {
    width: 56px;
    height: 56px;
    border-radius: 12px;
    display: grid;
    place-items: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    border: none;
    text-decoration: none;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }

  .create-btn {
    background-color: #2196F3;
    color: white;
  }

  .portfolio-btn {
    background-color: white;
    color: #333;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .fab:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    .nav-buttons {
      top: auto;
      bottom: 30px;
      right: 50%;
      transform: translateX(50%);
      flex-direction: row;
      padding: 0.75rem;
      background: white;
      border-radius: 16px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    }

    .fab {
      width: 48px;
      height: 48px;
    }
  }
</style>