<script>
  import { onMount, createEventDispatcher } from 'svelte';
  import { fade, slide } from 'svelte/transition';
  import { clientStore } from '../../stores/client';
  import ClientForm from './ClientForm.svelte';

  const dispatch = createEventDispatcher();

  // Local state
  let showForm = false;
  let searchTerm = '';
  let isLoading = true;
  let error = null;

  // Debounce helper to prevent filtering on every keystroke.
  let debounceTimeout;
  function debounce(fn, delay = 300) {
    return (...args) => {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(() => fn(...args), delay);
    };
  }
  const updateSearch = debounce(() => {}, 300);

  // Filter the client list based on search input.
  $: filteredClients = ($clientStore.items || []).filter(client => {
    if (!searchTerm) return true;
    const search = searchTerm.toLowerCase();
    return (
      client.name?.toLowerCase().includes(search) ||
      client.contact_email?.toLowerCase().includes(search) ||
      client.phone_number?.toLowerCase().includes(search)
    );
  });

  function handleClose() {
    showForm = false;
    dispatch('close');
  }

  async function loadClients() {
    isLoading = true;
    error = null;
    try {
      await clientStore.load();
    } catch (err) {
      error = err.message;
      console.error('Error loading clients:', err);
    } finally {
      isLoading = false;
    }
  }

  async function handleClientCreated() {
    await loadClients();
    showForm = false;
  }

  onMount(loadClients);
</script>

<div class="client-list-container">
  <!-- Header -->
  <header class="list-header" role="banner">
    <button class="close-btn" on:click={handleClose} aria-label="Close Client List">&times;</button>
    <h2>Clients</h2>
    <button class="add-btn" on:click={() => showForm = true}>Add Client</button>
  </header>

  <!-- Content -->
  {#if !showForm}
    <div class="controls">
      <input
        type="text"
        placeholder="Search clients..."
        bind:value={searchTerm}
        on:input={updateSearch}
        class="search-input"
        aria-label="Search Clients"
      />
    </div>

    {#if isLoading}
      <div class="loading" aria-live="polite">Loading clients...</div>
    {:else if error}
      <div class="error">
        {error}
        <button on:click={loadClients}>Retry</button>
      </div>
    {:else if filteredClients.length === 0}
      <div class="empty">
        {searchTerm ? 'No matching clients found' : 'No clients added yet'}
        <button class="add-first" on:click={() => showForm = true}>Add Your First Client</button>
      </div>
    {:else}
      <div class="client-grid">
        {#each filteredClients as client (client.id)}
          <div class="client-card" in:fade={{ duration: 200 }} out:fade>
            <div class="client-info">
              <h3>{client.name}</h3>
              <div class="contact-info">
                {#if client.contact_email}
                  <a href="mailto:{client.contact_email}" class="contact email">✉️ {client.contact_email}</a>
                {/if}
                {#if client.phone_number}
                  <a href="tel:{client.phone_number}" class="contact phone">📞 {client.phone_number}</a>
                {/if}
              </div>
              {#if client.notes}
                <p class="notes">{client.notes}</p>
              {/if}
            </div>
            <div class="client-status">
              <span class="status-badge {client.is_active ? 'active' : 'inactive'}">
                {client.is_active ? 'Active' : 'Inactive'}
              </span>
            </div>
          </div>
        {/each}
      </div>
    {/if}
  {:else}
    <ClientForm on:close={() => showForm = false} on:created={handleClientCreated} />
  {/if}
</div>

<style>
  /* Centralized Theme Variables */
  :root {
    --primary-color: #2563eb;
    --primary-dark: #1d4ed8;
    --background-color: #f8fafc;
    --text-color: #111827;
    --border-color: #e5e7eb;
    --error-color: #dc2626;
    --font-family: system-ui, sans-serif;
  }

  /* Container */
  .client-list-container {
    background: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    font-family: var(--font-family);
  }

  /* Header */
  .list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid var(--border-color);
    position: sticky;
    top: 0;
    background: white;
    z-index: 10;
  }
  .list-header h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
    color: var(--text-color);
  }
  .close-btn {
    font-size: 2rem;
    background: none;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: background 0.2s;
  }
  .close-btn:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  .add-btn {
    background: var(--primary-color);
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    height: 48px;
    transition: background 0.2s;
  }
  .add-btn:hover {
    background: var(--primary-dark);
  }

  /* Controls */
  .controls {
    padding: 1rem;
    border-bottom: 1px solid var(--border-color);
    position: sticky;
    top: 64px;
    background: white;
    z-index: 9;
  }
  .search-input {
    width: 100%;
    padding: 0.75rem 1rem;
    border: 1px solid #d1d5db;
    border-radius: 0.5rem;
    font-size: 1rem;
    height: 48px;
  }

  /* Client Grid and Cards */
  .client-grid {
    padding: 1rem;
    display: grid;
    gap: 1rem;
    overflow-y: auto;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
  .client-card {
    padding: 1.5rem;
    border: 1px solid var(--border-color);
    border-radius: 0.5rem;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  .client-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  .client-info h3 {
    font-size: 1.125rem;
    font-weight: 600;
    margin: 0 0 0.75rem 0;
    color: var(--text-color);
  }
  .contact-info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .contact {
    color: var(--primary-color);
    text-decoration: none;
    font-size: 0.875rem;
    transition: color 0.2s;
  }
  .contact:hover {
    color: var(--primary-dark);
  }
  .notes {
    margin-top: 0.75rem;
    border-top: 1px solid var(--border-color);
    padding-top: 0.75rem;
    color: #6b7280;
    font-size: 0.875rem;
    line-height: 1.5;
  }
  .client-status {
    margin-left: 1rem;
  }
  .status-badge {
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;
  }
  .status-badge.active {
    background: #dcfce7;
    color: #166534;
  }
  .status-badge.inactive {
    background: #f3f4f6;
    color: #4b5563;
  }
  .loading, .error, .empty {
    padding: 2rem;
    text-align: center;
    color: #6b7280;
  }
  .error {
    color: var(--error-color);
    background: #fee2e2;
    border-radius: 0.5rem;
  }
  .add-first {
    margin-top: 1rem;
    color: var(--primary-color);
    text-decoration: underline;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
  }

  /* Clean, Minimal Modal Styles */
  .modal-overlay {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  .modal-container {
    background: #fff;
    border-radius: 1rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    max-width: 90%;
    width: 500px;
    max-height: 90vh;
    overflow-y: auto;
    padding: 1rem;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  @media (max-width: 640px) {
    .modal-container {
      width: 95%;
      height: 90vh;
      border-radius: 0;
      margin: 0;
    }
  }
</style>
