<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import Login from './Login.svelte';
  import Register from './Register.svelte';

  const dispatch = createEventDispatcher();
  let showRegister = false;
  let showTermsModal = false; // state for controlling the modal visibility

  /**
   * Handles successful login or registration.
   * Dispatches a 'success' event.
   */
  function handleSuccess() {
    try {
      console.log('[Log] handleSuccess triggered');
      dispatch('success');
    } catch (error) {
      console.error('Error dispatching success event:', error);
    }
  }

  /**
   * Toggles between Login and Register forms.
   */
  function toggleMode() {
    try {
      showRegister = !showRegister;
      console.log(`[Log] Toggled to ${showRegister ? 'Register' : 'Login'} mode`);
    } catch (error) {
      console.error('Error toggling auth mode:', error);
    }
  }

  // Log when the component is mounted
  onMount(() => {
    try {
      console.log('[Log] AuthModal mounted successfully (AuthModal.svelte)');
    } catch (error) {
      console.error('Error during onMount:', error);
    }
  });
</script>

<!-- Layout -->
<div class="auth-layout">
  <!-- Media Side -->
  <aside class="media-side">
    <div class="media-placeholder">
      <p>Your product images, slideshow, or video goes here.</p>
    </div>
  </aside>

  <!-- Auth Side -->
  <section class="auth-side">
    {#if showRegister}
      <Register on:success={handleSuccess} />
      <button class="toggle-auth" on:click={toggleMode}>
        Already have an account? Log in
      </button>
    {:else}
      <Login on:success={handleSuccess} />
      <button class="toggle-auth" on:click={toggleMode}>
        Need an account? Register
      </button>
    {/if}

    <!-- Terms and Conditions Disclaimer -->
    <p class="terms-disclaimer">
      By {showRegister ? 'registering' : 'logging in'}, you agree to our
      <a href="#" on:click|preventDefault={() => showTermsModal = true}>Terms and Conditions</a>.
    </p>
  </section>
</div>

<!-- Terms and Conditions Modal -->
{#if showTermsModal}
  <div class="modal-backdrop" on:click={() => showTermsModal = false}>
    <div class="modal-content" on:click|stopPropagation>
      <button class="close-modal" on:click={() => showTermsModal = false}>Close</button>
      <div class="terms-text">
        <h2>Terms of Service for ArchX Platform</h2>
        <p><strong>Last Updated:</strong> February 11, 2025</p>
        
        <h3>1. Introduction</h3>
        <p>
          Welcome to ArchX ("Platform", "we", "us", "our"). By accessing or using our platform, you agree to be bound by these Terms of Service ("Terms"). These Terms constitute a legally binding agreement between you and ArchX.
        </p>
        
        <h3>2. Definitions</h3>
        <ul>
          <li><strong>Platform:</strong> refers to the ArchX website, mobile applications, and services.</li>
          <li><strong>User:</strong> refers to any individual or entity that accesses or uses the Platform.</li>
          <li><strong>Client:</strong> refers to property owners or managers who use the Platform to manage projects.</li>
          <li><strong>Contractor:</strong> refers to service providers who perform work through the Platform.</li>
          <li><strong>Communications:</strong> refers to notifications, updates, and messages sent through the Platform.</li>
        </ul>
        
        <h3>3. Communication Consent</h3>
        <p>By using our Platform, you expressly consent to receive communications from ArchX and other Platform users, including:</p>
        <ul>
          <li>
            <strong>SMS/Text Messages:</strong>
            <ul>
              <li>Project status updates</li>
              <li>Work order notifications</li>
              <li>Schedule confirmations</li>
              <li>Important alerts about your projects</li>
              <li>Service completion notifications</li>
              <li>Payment confirmations</li>
              <li>Safety and emergency notifications</li>
            </ul>
          </li>
          <li>
            <strong>Frequency of Messages:</strong>
            <ul>
              <li>You may receive up to 30 messages per month</li>
              <li>Message frequency varies based on project activity</li>
              <li>Standard message and data rates may apply</li>
            </ul>
          </li>
          <li>
            <strong>Opt-Out Instructions:</strong>
            <ul>
              <li>You can opt out of SMS messages at any time by:</li>
              <li>Replying "STOP" to any message</li>
              <li>Adjusting your notification preferences in your account settings</li>
              <li>Contacting our support team</li>
            </ul>
          </li>
        </ul>
        
        <h3>4. User Data and Privacy</h3>
        <p>We collect and process your information in accordance with our Privacy Policy. By using the Platform, you consent to:</p>
        <ul>
          <li>
            <strong>Data Collection:</strong>
            <ul>
              <li>Contact information (phone, email)</li>
              <li>Project details and history</li>
              <li>Communication preferences</li>
              <li>Usage data and analytics</li>
            </ul>
          </li>
          <li>
            <strong>Data Usage:</strong>
            <ul>
              <li>Sending service-related communications</li>
              <li>Platform functionality and improvement</li>
              <li>Analysis and reporting</li>
              <li>Legal compliance</li>
            </ul>
          </li>
        </ul>
        
        <h3>5. Platform Usage Guidelines</h3>
        <p>Users agree to:</p>
        <ul>
          <li>Provide accurate contact information</li>
          <li>Maintain account security</li>
          <li>Use the Platform for legitimate business purposes</li>
          <li>Comply with all applicable laws and regulations</li>
        </ul>
        
        <h3>6. Service Providers</h3>
        <p>We may use third-party service providers to:</p>
        <ul>
          <li>Process communications</li>
          <li>Handle payment transactions</li>
          <li>Manage user authentication</li>
          <li>Provide analytics services</li>
        </ul>
        
        <h3>7. Modifications</h3>
        <p>We reserve the right to modify these Terms at any time. Users will be notified of significant changes via:</p>
        <ul>
          <li>Email notification</li>
          <li>Platform announcement</li>
          <li>SMS notification (if opted in)</li>
        </ul>
        
        <h3>8. Limited License</h3>
        <p>Users are granted a limited, non-exclusive license to use the Platform in accordance with these Terms.</p>
        
        <h3>9. Termination</h3>
        <p>We reserve the right to terminate or suspend access to our Platform for violations of these Terms or any applicable laws.</p>
        
        <h3>10. Communication Records</h3>
        <p>We maintain records of:</p>
        <ul>
          <li>User consent for communications</li>
          <li>Opt-out requests</li>
          <li>Communication history</li>
          <li>Platform usage logs</li>
        </ul>
        
        <h3>11. Compliance with Telecommunications Laws</h3>
        <p>We comply with:</p>
        <ul>
          <li>Telephone Consumer Protection Act (TCPA)</li>
          <li>CAN-SPAM Act</li>
          <li>State and federal communications regulations</li>
          <li>Industry standard practices for electronic communications</li>
        </ul>
        
        <h3>12. Dispute Resolution</h3>
        <p>Any disputes arising from these Terms will be resolved through:</p>
        <ul>
          <li>Initial informal negotiation</li>
          <li>Mediation if necessary</li>
          <li>Binding arbitration as a last resort</li>
        </ul>
        
        <h3>13. Limitation of Liability</h3>
        <p>Our liability is limited to the maximum extent permitted by law. We are not liable for:</p>
        <ul>
          <li>Service interruptions</li>
          <li>Message delivery failures</li>
          <li>Carrier-related issues</li>
          <li>Data transmission errors</li>
        </ul>
        
        <h3>14. Contact Information</h3>
        <p>For questions about these Terms:</p>
        <ul>
          <li>Email: support@archx.org</li>
          <li>21812 South Brandon St. Farmington Hills, MI 48336</li>
          <li>Phone: (844)731-3802</li>
        </ul>
        
        <p>
          By using the Platform, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.
        </p>
      </div>
    </div>
  </div>
{/if}

<style>
  /* Fixed Full-Screen Layout */
  .auth-layout {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100vw;
    background-color: white;
    z-index: 1000;
    overflow: hidden;
    font-family: Arial, sans-serif;
  }
  
  /* Media Side */
  .media-side {
    flex: 1 1 55%;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    box-sizing: border-box;
  }
  
  .media-placeholder {
    text-align: center;
    color: #555;
    max-width: 500px;
  }
  
  /* Auth Side */
  .auth-side {
    flex: 1 1 45%;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem;
    box-sizing: border-box;
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
  }
  
  .toggle-auth {
    margin-top: 1.5rem;
    background: none;
    border: none;
    color: #3498db;
    padding: 0.75rem 1.5rem;
    cursor: pointer;
    text-decoration: underline;
    font-size: 1rem;
    transition: color 0.2s;
  }
  
  .toggle-auth:hover {
    color: #2980b9;
  }
  
  .terms-disclaimer {
    margin-top: 1rem;
    font-size: 0.85rem;
    color: #666;
  }
  
  .terms-disclaimer a {
    color: #3498db;
    text-decoration: underline;
    cursor: pointer;
  }
  
  /* Modal Styles */
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1100;
  }
  
  .modal-content {
    background: #fff;
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;
    padding: 1.5rem;
    border-radius: 8px;
    position: relative;
  }
  
  .close-modal {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    color: #333;
  }
  
  .terms-text h2,
  .terms-text h3 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  
  .terms-text p,
  .terms-text ul {
    margin-bottom: 1rem;
    font-size: 0.9rem;
    color: #333;
  }
  
  @media (max-width: 800px) {
    .auth-layout {
      flex-direction: column;
    }
  
    .media-side {
      display: none;
    }
  
    .auth-side {
      flex: 1 1 auto;
      width: 100%;
      box-shadow: none;
      padding: 2rem 1rem;
    }
  }
</style>
