<script>
  import { onMount, createEventDispatcher } from 'svelte';
  import { fade, slide } from 'svelte/transition';
  import { projectStore } from '../../stores/project';
  import ProjectForm from './ProjectForm.svelte';

  const dispatch = createEventDispatcher();
  
  let showForm = false;
  let searchTerm = '';
  let statusFilter = 'ALL';
  let isLoading = true;
  let error = null;

  // Debounce helper to prevent filtering on every keystroke.
  let debounceTimeout;
  function debounce(fn, delay = 300) {
    return (...args) => {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(() => fn(...args), delay);
    };
  }
  const updateSearch = debounce(() => {
    // Reactivity is triggered via the updated searchTerm.
  }, 300);

  // Reactive filtering of projects.
  $: filteredProjects = ($projectStore.items || [])
    .filter(p => statusFilter === 'ALL' || p.status === statusFilter)
    .filter(p => {
      if (!searchTerm) return true;
      const s = searchTerm.toLowerCase();
      return (
        p.title?.toLowerCase().includes(s) ||
        p.description?.toLowerCase().includes(s) ||
        p.client_name?.toLowerCase().includes(s)
      );
    });

  function handleClose() {
    showForm = false;
    dispatch('close');
  }

  async function loadProjects() {
    isLoading = true;
    error = null;
    try {
      await projectStore.load();
    } catch (err) {
      error = err.message;
      console.error('Error loading projects:', err);
    } finally {
      isLoading = false;
    }
  }

  async function handleProjectCreated() {
    showForm = false;
    await loadProjects();
  }

  onMount(loadProjects);

  // Helper functions.
  function formatDate(date) {
    if (!date) return '';
    return new Date(date).toLocaleDateString();
  }
  function formatCurrency(amount) {
    if (!amount) return '';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  }
  function getStatusColor(status) {
    switch (status) {
      case 'PLANNING': return 'planning';
      case 'IN_PROGRESS': return 'in-progress';
      case 'ON_HOLD': return 'on-hold';
      case 'COMPLETED': return 'completed';
      case 'CANCELLED': return 'cancelled';
      default: return 'default';
    }
  }
</script>

<div class="project-list-container">
  <!-- Controls -->
  {#if !showForm}
    <div class="controls">
      <input
        type="text"
        placeholder="Search projects..."
        bind:value={searchTerm}
        on:input={updateSearch}
        class="search-input"
        aria-label="Search Projects"
      />
      <div class="status-filters">
        <button
          class="status-btn {statusFilter === 'ALL' ? 'active' : ''}"
          on:click={() => statusFilter = 'ALL'}
        >
          All
        </button>
        <button
          class="status-btn {statusFilter === 'IN_PROGRESS' ? 'active' : ''}"
          on:click={() => statusFilter = 'IN_PROGRESS'}
        >
          In Progress
        </button>
        <button
          class="status-btn {statusFilter === 'PLANNING' ? 'active' : ''}"
          on:click={() => statusFilter = 'PLANNING'}
        >
          Planning
        </button>
        <button
          class="status-btn {statusFilter === 'ON_HOLD' ? 'active' : ''}"
          on:click={() => statusFilter = 'ON_HOLD'}
        >
          On Hold
        </button>
      </div>
    </div>
  {/if}

  {#if isLoading}
    <div class="loading" aria-live="polite">Loading projects...</div>
  {:else if error}
    <div class="error">
      {error}
      <button on:click={loadProjects}>Retry</button>
    </div>
  {:else if filteredProjects.length === 0}
    <div class="empty">
      {searchTerm || statusFilter !== 'ALL' 
        ? 'No matching projects found' 
        : 'No projects added yet'}
      <button class="add-first" on:click={() => showForm = true}>
        Create Your First Project
      </button>
    </div>
  {:else}
    <div class="project-grid">
      {#each filteredProjects as project (project.id)}
        <div class="project-card" in:fade={{duration: 200}} out:fade>
          <div class="project-header">
            <div class="project-title">
              <h3>{project.title}</h3>
              <span class="status-badge {getStatusColor(project.status)}">
                {project.status}
              </span>
            </div>
            {#if project.budget}
              <div class="budget">
                {formatCurrency(project.budget)}
              </div>
            {/if}
          </div>

          {#if project.client_name}
            <div class="client-info">
              <span class="label">Client:</span>
              <span class="value">{project.client_name}</span>
            </div>
          {/if}

          {#if project.description}
            <p class="description">{project.description}</p>
          {/if}

          <div class="project-details">
            <div class="dates">
              {#if project.start_date}
                <div class="date">
                  <span class="label">Start:</span>
                  <span class="value">{formatDate(project.start_date)}</span>
                </div>
              {/if}
              {#if project.end_date}
                <div class="date">
                  <span class="label">Est. End:</span>
                  <span class="value">{formatDate(project.end_date)}</span>
                </div>
              {/if}
            </div>
            <div class="stats">
              <div class="stat">
                <span class="label">Work Orders:</span>
                <span class="value">{project.active_work_orders_count || 0} active</span>
              </div>
              <div class="stat">
                <span class="label">Completed:</span>
                <span class="value">{project.completed_work_orders_count || 0}</span>
              </div>
            </div>
          </div>
        </div>
      {/each}
    </div>
  {/if}

  {#if showForm}
    <!-- Minimal Modal for ProjectForm -->
    <div class="modal-overlay">
      <div class="modal-container">
        <ProjectForm on:close={() => showForm = false} on:created={handleProjectCreated} />
      </div>
    </div>
  {/if}
</div>

<style>
  /* Centralized Theme */
  :root {
    --primary-color: #2563eb;
    --primary-dark: #1d4ed8;
    --background-color: #f8fafc;
    --text-color: #111827;
    --border-color: #e5e7eb;
    --error-color: #dc2626;
    --font-family: system-ui, sans-serif;
  }

  .project-list-container {
    background: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    font-family: var(--font-family);
  }

  /* Controls & Filters */
  .controls {
    padding: 1rem;
    border-bottom: 1px solid var(--border-color);
    position: sticky;
    top: 64px;
    background: white;
    z-index: 9;
  }
  .search-input {
    width: 100%;
    padding: 0.75rem 1rem;
    border: 1px solid #d1d5db;
    border-radius: 0.5rem;
    font-size: 1rem;
    height: 48px;
    margin-bottom: 1rem;
  }
  .status-filters {
    display: flex;
    gap: 0.5rem;
    overflow-x: auto;
    padding-bottom: 0.5rem;
  }
  .status-btn {
    background: #f3f4f6;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 9999px;
    font-size: 1rem;
    cursor: pointer;
    white-space: nowrap;
    height: 48px;
    transition: background 0.2s;
  }
  .status-btn.active {
    background: var(--primary-color);
    color: white;
  }

  /* Project Grid & Cards */
  .project-grid {
    padding: 1rem;
    display: grid;
    gap: 1rem;
    overflow-y: auto;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
  .project-card {
    padding: 1.5rem;
    border: 1px solid var(--border-color);
    border-radius: 0.5rem;
    background: white;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  .project-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  .project-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
  }
  .project-title {
    flex: 1;
    margin-right: 1rem;
  }
  .project-title h3 {
    font-size: 1.125rem;
    font-weight: 600;
    margin: 0 0 0.5rem 0;
    color: var(--text-color);
  }
  .status-badge {
    display: inline-block;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;
  }
  /* Status Colors */
  .status-badge.planning { background: #dbeafe; color: #1e40af; }
  .status-badge.in-progress { background: #dcfce7; color: #166534; }
  .status-badge.on-hold { background: #fef3c7; color: #92400e; }
  .status-badge.completed { background: #f3f4f6; color: #4b5563; }
  .status-badge.cancelled { background: #fee2e2; color: var(--error-color); }
  
  .client-info {
    margin-bottom: 1rem;
    font-size: 0.875rem;
  }
  .label {
    color: #6b7280;
    font-weight: 500;
  }
  .value {
    color: var(--text-color);
  }
  .description {
    margin: 0 0 1rem;
    color: #6b7280;
    font-size: 0.875rem;
    line-height: 1.5;
  }
  .project-details {
    display: grid;
    gap: 1rem;
    font-size: 0.875rem;
    border-top: 1px solid var(--border-color);
    padding-top: 1rem;
  }
  .dates {
    display: flex;
    gap: 1.5rem;
  }
  .date {
    display: flex;
    gap: 0.5rem;
  }
  .stats {
    display: flex;
    gap: 1.5rem;
  }
  .stat {
    display: flex;
    gap: 0.5rem;
  }
  .budget {
    font-weight: 600;
    color: #059669;
    font-size: 1.125rem;
  }
  .loading, .error, .empty {
    padding: 2rem;
    text-align: center;
    color: #6b7280;
  }
  .error {
    color: var(--error-color);
    background: #fee2e2;
    border-radius: 0.5rem;
    margin: 1rem;
  }
  .add-first {
    margin-top: 1rem;
    color: var(--primary-color);
    text-decoration: underline;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
  }

  /* Minimal Modal for ProjectForm */
  .modal-overlay {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  .modal-container {
    background: #fff;
    border-radius: 1rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    max-width: 90%;
    width: 500px;
    max-height: 90vh;
    overflow-y: auto;
    padding: 1rem;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  @media (max-width: 640px) {
    .modal-container {
      width: 95%;
      height: 90vh;
      border-radius: 0;
      margin: 0;
    }
  }
</style>
