<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { fade, slide } from 'svelte/transition';
  import { cubicOut } from 'svelte/easing';
  import { clientStore } from '../../stores/client';
  import { worker } from '../../stores/worker';
  import { projectStore } from '../../stores/project';
  import WorkOrderForm from '../workorders/WorkOrderForm.svelte';

  const dispatch = createEventDispatcher();
  const isMobile = typeof window !== 'undefined' ? window.innerWidth <= 640 : false;

  // --- Drag to close (mobile) ---
  let startY = 0;
  let currentY = 0;
  let isDragging = false;
  const CLOSE_THRESHOLD = 150;
  let isClosing = false;
  $: sheetStyle = isDragging ? `transform: translateY(${currentY}px)` : '';

  function handleTouchStart(e) {
    if (isClosing) return;
    startY = e.touches[0].clientY;
    isDragging = true;
  }
  function handleTouchMove(e) {
    if (!isDragging || isClosing) return;
    const delta = e.touches[0].clientY - startY;
    if (delta < 0) return;
    currentY = delta;
    e.preventDefault();
  }
  function handleTouchEnd() {
    if (!isDragging || isClosing) return;
    if (currentY > CLOSE_THRESHOLD) {
      isClosing = true;
      if (window.navigator?.vibrate) {
        window.navigator.vibrate(50);
      }
      dispatch('close');
    }
    currentY = 0;
    isDragging = false;
  }

  // --- Form Data & Offline Support ---
  let formData = {
    title: '',
    description: '',
    status: 'PLANNING',
    client_id: '',
    start_date: '',
    estimated_end_date: '',
    budget: null,
    workers: []
  };

  let clients = [];
  let availableWorkers = [];
  let isLoading = false;
  let error = null;
  let showWorkOrderForm = false;

  // Only show errors after the first submit attempt.
  let submitAttempted = false;

  let isOffline = !navigator.onLine;
  function updateOnlineStatus() {
    isOffline = !navigator.onLine;
  }

  onMount(() => {
    // Lock body scroll when modal is open.
    document.body.style.overflow = 'hidden';
    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);
    loadFormData();

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
      document.body.style.overflow = '';
    };
  });

  // --- Validation ---
  $: isValid = formData.title.trim().length > 0;
  $: formErrors = {
    title: submitAttempted && !formData.title.trim() ? 'Title is required' : '',
    budget:
      submitAttempted && formData.budget !== null && isNaN(parseFloat(formData.budget))
        ? 'Invalid budget amount'
        : ''
  };

  async function loadFormData() {
    try {
      await clientStore.load();
      await worker.loadWorkers();
      clients = $clientStore.items;
      availableWorkers = $worker.workers;
    } catch (err) {
      error = 'Failed to load form data';
      console.error('Error loading form data:', err);
    }
  }

  function handleBudgetInput(e) {
    let value = e.target.value.replace(/[^\d.]/g, '');
    const parts = value.split('.');
    if (parts.length > 2) {
      value = parts[0] + '.' + parts.slice(1).join('');
    }
    if (value) {
      const num = parseFloat(value);
      if (!isNaN(num)) {
        formData.budget = num;
        e.target.value = num.toLocaleString('en-US');
      }
    } else {
      formData.budget = null;
      e.target.value = '';
    }
  }

  function toggleWorker(workerId) {
    if (!formData.workers.includes(workerId)) {
      formData.workers = [...formData.workers, workerId];
    } else {
      formData.workers = formData.workers.filter(id => id !== workerId);
    }
  }

  async function handleSubmit() {
    submitAttempted = true;
    if (!isValid) {
      error = 'Please correct the form errors';
      return;
    }
    isLoading = true;
    error = null;

    const projectData = {
      title: formData.title.trim(),
      description: formData.description.trim() || '',
      status: formData.status,
      client: formData.client_id || null,
      start_date: formData.start_date || null,
      end_date: formData.estimated_end_date || null,
      budget: formData.budget ? parseFloat(formData.budget) : null
    };

    try {
      // Offline: store data locally for later sync.
      if (isOffline) {
        localStorage.setItem('offlineProject', JSON.stringify(projectData));
        localStorage.setItem('offlineWorkers', JSON.stringify(formData.workers));
        showWorkOrderForm = true;
        dispatch('created', { project: projectData, offline: true });
        return;
      }

      // Online: create project and add workers.
      const newProject = await projectStore.add(projectData);
      for (const workerId of formData.workers) {
        await projectStore.addWorker(newProject.id, workerId);
      }
      showWorkOrderForm = true;
      dispatch('created', { project: newProject });
    } catch (err) {
      console.error('Error creating project:', err);
      error = err.message || 'Failed to create project';
    } finally {
      isLoading = false;
    }
  }

  function handleWorkOrderSaved() {
    dispatch('close');
  }
</script>

<!-- Modal overlay and sheet matching your other files -->
<div
  class="modal-overlay"
  on:click={() => dispatch('close')}
  transition:fade={{ duration: 200 }}
>
  <div
    class="modal-sheet"
    style={sheetStyle}
    on:click|stopPropagation
    transition:slide={{ duration: 300, easing: cubicOut, axis: 'y' }}
    on:touchstart={isMobile ? handleTouchStart : null}
    on:touchmove={isMobile ? handleTouchMove : null}
    on:touchend={isMobile ? handleTouchEnd : null}
  >
    {#if isMobile}
      <div class="drag-handle">
        <div class="handle-bar"></div>
      </div>
    {/if}

    <div class="project-form">
      {#if isOffline}
        <div class="offline-banner">
          You are currently offline. Changes will be saved locally and synced when online.
        </div>
      {/if}
      <div class="form-header">
        <button type="button" class="back-btn" on:click={() => dispatch('close')}>
          ←
        </button>
        <h2>Project Details</h2>
        <button
          type="submit"
          class="save-btn"
          disabled={!isValid || isLoading}
          on:click={handleSubmit}
        >
          {isLoading ? 'Creating...' : 'Save'}
        </button>
      </div>

      {#if error}
        <div class="error-message" role="alert">{error}</div>
      {/if}

      <!-- Scrollable container for form content -->
      <div class="scrollable-container">
        <form on:submit|preventDefault={handleSubmit} class="form-content">
          <div class="form-group">
            <label for="title">Project Title <span class="required">*</span></label>
            <input
              type="text"
              id="title"
              bind:value={formData.title}
              placeholder="Enter project title"
              class:error={formErrors.title}
              required
            />
            {#if formErrors.title}
              <span class="error-text">{formErrors.title}</span>
            {/if}
          </div>

          <div class="form-group">
            <label for="client">Client</label>
            <select id="client" bind:value={formData.client_id}>
              <option value="">Select a client</option>
              {#each clients as c}
                <option value={c.id}>{c.name}</option>
              {/each}
            </select>
          </div>

          <div class="form-group">
            <label for="description">Description</label>
            <textarea
              id="description"
              bind:value={formData.description}
              placeholder="Enter project description"
              rows="4"
            ></textarea>
          </div>

          <div class="form-row">
            <div class="form-group">
              <label for="start_date">Start Date</label>
              <input type="date" id="start_date" bind:value={formData.start_date} />
            </div>
            <div class="form-group">
              <label for="estimated_end_date">Estimated End Date</label>
              <input type="date" id="estimated_end_date" bind:value={formData.estimated_end_date} />
            </div>
          </div>

          <div class="form-group">
            <label for="budget">Budget</label>
            <input type="text" id="budget" placeholder="1,000.00" on:input={handleBudgetInput} />
            {#if formErrors.budget}
              <span class="error-text">{formErrors.budget}</span>
            {/if}
          </div>

          <div class="form-group">
            <label for="status">Status</label>
            <select id="status" bind:value={formData.status}>
              <option value="PLANNING">Planning</option>
              <option value="IN_PROGRESS">In Progress</option>
              <option value="ON_HOLD">On Hold</option>
              <option value="COMPLETED">Completed</option>
              <option value="CANCELLED">Cancelled</option>
            </select>
          </div>

          <!-- Workers Assignment Section -->
          <div class="form-section">
            <h3>Assign Workers</h3>
            <div class="workers-grid">
              {#each availableWorkers as w}
                <label class="worker-checkbox">
                  <input
                    type="checkbox"
                    checked={formData.workers.includes(w.id)}
                    on:change={() => toggleWorker(w.id)}
                  />
                  <span class="worker-info">
                    <span class="worker-name">{w.name}</span>
                    {#if w.title}
                      <span class="worker-title">{w.title}</span>
                    {/if}
                    <span class="worker-status {w.status.toLowerCase()}">{w.status}</span>
                  </span>
                </label>
              {/each}
            </div>
          </div>

          <!-- Work Orders Section -->
          <div class="form-section">
            <h3>Work Orders</h3>
            {#if showWorkOrderForm}
              <div class="form-content-workorder">
                <WorkOrderForm
                  on:saved={handleWorkOrderSaved}
                  on:close={() => (showWorkOrderForm = false)}
                />
              </div>
            {/if}
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<style>
  /* Modal & Sheet */
  .modal-overlay {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
    display: grid;
    place-items: center;
    z-index: 50;
    backdrop-filter: blur(4px);
  }
  .modal-sheet {
    background: white;
    width: 100%;
    max-width: 600px;
    /* Force fixed height so inner content can scroll */
    height: 90vh;
    border-radius: 1rem;
    overflow: hidden;
    position: relative;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }

  /* Drag Handle (mobile) */
  .drag-handle {
    height: 24px;
    display: grid;
    place-items: center;
    background: white;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    touch-action: none;
  }
  .handle-bar {
    width: 32px;
    height: 4px;
    background: #e5e7eb;
    border-radius: 2px;
  }

  /* Main Form Container */
  .project-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: white;
  }
  .offline-banner {
    background: #fffbcc;
    color: #665c00;
    padding: 0.75rem 1rem;
    text-align: center;
    font-size: 0.875rem;
  }

  /* Header */
  .form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #e5e7eb;
    position: sticky;
    top: 0;
    background: white;
    z-index: 10;
  }
  .form-header h2 {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
  }
  .back-btn,
  .save-btn {
    height: 48px;
    min-width: 48px;
    border: none;
    border-radius: 0.5rem;
    font-size: 1.125rem;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
  }
  .back-btn {
    background: transparent;
    font-size: 1.5rem;
  }
  .back-btn:hover:not(:disabled) {
    background: #f3f4f6;
  }
  .save-btn {
    background: #2563eb;
    color: white;
  }
  .save-btn:hover:not(:disabled) {
    background: #1d4ed8;
  }
  .save-btn:disabled {
    background: #93c5fd;
    cursor: not-allowed;
  }

  /* Error Message */
  .error-message {
    margin: 1rem;
    padding: 1rem;
    background: #fee2e2;
    color: #dc2626;
    border-radius: 0.5rem;
    font-size: 0.875rem;
  }

  /* Scrollable container for form content */
  .scrollable-container {
    flex: 1;
    overflow-y: auto;
  }
  .form-content {
    padding: 1rem;
  }
  .form-section {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #e5e7eb;
  }
  .form-section h3 {
    font-size: 1.125rem;
    font-weight: 600;
    margin: 0 0 1rem 0;
    color: #111827;
  }
  .form-group {
    margin-bottom: 1.5rem;
  }
  .form-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
    margin-bottom: 1rem;
  }
  label {
    display: block;
    font-weight: 500;
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }
  .required {
    color: #dc2626;
  }
  input,
  select,
  textarea {
    width: 100%;
    padding: 0.75rem 1rem;
    border: 1px solid #d1d5db;
    border-radius: 0.5rem;
    font-size: 1rem;
    background: white;
    height: 48px;
  }
  textarea {
    height: auto;
    min-height: 96px;
    resize: vertical;
  }
  input:focus,
  select:focus,
  textarea:focus {
    outline: none;
    border-color: #2563eb;
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
  }
  input.error,
  select.error,
  textarea.error {
    border-color: #dc2626;
  }
  .error-text {
    color: #dc2626;
    font-size: 0.875rem;
    margin-top: 0.25rem;
    display: block;
  }

  /* Workers Grid */
  .workers-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 0.75rem;
  }
  .worker-checkbox {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.2s;
  }
  .worker-checkbox:hover:not(:disabled) {
    border-color: #d1d5db;
    background: #f9fafb;
  }
  .worker-info {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  .worker-name {
    font-weight: 500;
  }
  .worker-title {
    color: #6b7280;
    font-size: 0.875rem;
  }
  .worker-status {
    display: inline-block;
    padding: 0.25rem 0.5rem;
    border-radius: 9999px;
    font-size: 0.75rem;
    font-weight: 500;
  }
  .worker-status.available { background: #dcfce7; color: #166534; }
  .worker-status.busy { background: #fef3c7; color: #92400e; }
  .worker-status.on_leave { background: #dbeafe; color: #1e40af; }
  .worker-status.inactive { background: #f3f4f6; color: #4b5563; }

  /* Work Order Form Container */
  .form-content-workorder {
    background: #f8fafc;
    padding: 1.5rem;
    border-radius: 0.5rem;
    margin-top: 1rem;
  }

  /* Mobile Optimizations */
  @media screen and (max-width: 640px) {
    .scrollable-container {
      padding-bottom: 4rem;
    }
    input,
    select,
    textarea {
      font-size: 16px;
    }
    .form-row {
      grid-template-columns: 1fr;
    }
    .workers-grid {
      grid-template-columns: 1fr;
    }
    .worker-checkbox {
      padding: 1rem;
    }
    .back-btn,
    .save-btn {
      min-width: 40px;
      height: 44px;
      font-size: 1rem;
    }
  }
</style>
