<script>
  import { onMount, createEventDispatcher } from 'svelte';
  import { fade, slide } from 'svelte/transition';
  import { worker } from '../../stores/worker';
  import WorkerForm from './WorkerForm.svelte';

  const dispatch = createEventDispatcher();
  let showForm = false;
  let searchTerm = '';
  let statusFilter = 'ALL';
  let isLoading = true;
  let error = null;

  // Debounce helper so that search filtering isn't triggered on every keystroke.
  let debounceTimeout;
  function debounce(fn, delay = 300) {
    return (...args) => {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(() => fn(...args), delay);
    };
  }
  // Use a debounced function for search updates.
  const updateSearch = debounce(() => {
    // (Reactive $: filteredWorkers recalculates automatically since searchTerm is updated)
  }, 300);

  // Reactive filtering of workers
  $: filteredWorkers = ($worker.workers || [])
    .filter(w => statusFilter === 'ALL' || w.status === statusFilter)
    .filter(w => {
      if (!searchTerm) return true;
      const s = searchTerm.toLowerCase();
      return (
        w.name?.toLowerCase().includes(s) ||
        w.phone?.toLowerCase().includes(s) ||
        w.email?.toLowerCase().includes(s)
      );
    });

  function handleClose() {
    showForm = false;
    dispatch('close');
  }

  async function loadWorkers() {
    isLoading = true;
    error = null;
    try {
      await worker.loadWorkers();
    } catch (err) {
      error = err.message;
      console.error('Error loading workers:', err);
    } finally {
      isLoading = false;
    }
  }

  async function handleWorkerCreated() {
    await loadWorkers();
    showForm = false;
  }

  onMount(loadWorkers);
</script>

<div class="worker-list-container">
  <!-- Header -->
  <header class="list-header" role="banner">
    <button class="close-btn" on:click={handleClose} aria-label="Close Worker List">&times;</button>
    <h2>Workers</h2>
    <button class="add-btn" on:click={() => showForm = true}>Add Worker</button>
  </header>

  <!-- Search & Filters -->
  {#if !showForm}
    <div class="controls">
      <input
        type="text"
        placeholder="Search workers..."
        bind:value={searchTerm}
        on:input={updateSearch}
        class="search-input"
        aria-label="Search Workers"
      />
      <div class="status-filters">
        <button
          class="status-btn {statusFilter === 'ALL' ? 'active' : ''}"
          on:click={() => statusFilter = 'ALL'}
        >
          All
        </button>
        <button
          class="status-btn {statusFilter === 'AVAILABLE' ? 'active' : ''}"
          on:click={() => statusFilter = 'AVAILABLE'}
        >
          Available
        </button>
        <button
          class="status-btn {statusFilter === 'BUSY' ? 'active' : ''}"
          on:click={() => statusFilter = 'BUSY'}
        >
          Busy
        </button>
      </div>
    </div>

    <!-- Worker List -->
    {#if isLoading}
      <div class="loading" aria-live="polite">Loading workers...</div>
    {:else if error}
      <div class="error">
        {error}
        <button on:click={loadWorkers}>Retry</button>
      </div>
    {:else if filteredWorkers.length === 0}
      <div class="empty">
        {searchTerm ? 'No matching workers found' : 'No workers added yet'}
        <button class="add-first" on:click={() => showForm = true}>
          Add Your First Worker
        </button>
      </div>
    {:else}
      <div class="worker-grid">
        {#each filteredWorkers as w (w.id)}
          <div class="worker-card" in:fade={{ duration: 200 }} out:fade>
            <div class="worker-info">
              <h3>{w.name}</h3>
              {#if w.phone}
                <a href="tel:{w.phone}" class="contact phone" aria-label="Call worker">
                  📞 {w.phone}
                </a>
              {/if}
              {#if w.email}
                <a href="mailto:{w.email}" class="contact email" aria-label="Email worker">
                  ✉️ {w.email}
                </a>
              {/if}
            </div>
            <div class="status {w.status.toLowerCase()}">
              {w.status}
            </div>
          </div>
        {/each}
      </div>
    {/if}
  {:else}
    <!-- Modal for WorkerForm -->
    <div class="modal-overlay">
      <div class="modal-container">
        <WorkerForm on:close={() => showForm = false} on:created={handleWorkerCreated} />
      </div>
    </div>
  {/if}
</div>

<style>
  /* Centralized Theme Variables */
  :root {
    --primary-color: #2563eb;
    --primary-dark: #1d4ed8;
    --background-color: #f8fafc;
    --text-color: #111827;
    --border-color: #e5e7eb;
    --error-color: #dc2626;
    --font-family: system-ui, sans-serif;
  }

  .worker-list-container {
    background: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    font-family: var(--font-family);
  }

  /* Header */
  .list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid var(--border-color);
    position: sticky;
    top: 0;
    background: white;
    z-index: 10;
  }
  .list-header h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
    color: var(--text-color);
  }
  .close-btn {
    font-size: 2rem;
    background: none;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: background 0.2s;
  }
  .close-btn:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  .add-btn {
    background: var(--primary-color);
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    height: 48px;
    transition: background 0.2s;
  }
  .add-btn:hover {
    background: var(--primary-dark);
  }

  /* Controls */
  .controls {
    padding: 1rem;
    border-bottom: 1px solid var(--border-color);
    background: white;
  }
  .search-input {
    width: 100%;
    padding: 0.75rem 1rem;
    border: 1px solid #d1d5db;
    border-radius: 0.5rem;
    font-size: 1rem;
    height: 48px;
    margin-bottom: 1rem;
  }
  .status-filters {
    display: flex;
    gap: 0.5rem;
    overflow-x: auto;
    padding-bottom: 0.5rem;
  }
  .status-btn {
    background: #f3f4f6;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 9999px;
    font-size: 1rem;
    cursor: pointer;
    white-space: nowrap;
    height: 48px;
    transition: background 0.2s;
  }
  .status-btn.active {
    background: var(--primary-color);
    color: white;
  }

  /* Worker Grid & Cards */
  .worker-grid {
    padding: 1rem;
    display: grid;
    gap: 1rem;
    overflow-y: auto;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
  .worker-card {
    padding: 1.5rem;
    border: 1px solid var(--border-color);
    border-radius: 0.5rem;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  .worker-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  .worker-info h3 {
    font-size: 1.125rem;
    font-weight: 600;
    margin: 0 0 0.5rem 0;
    color: var(--text-color);
  }
  .contact {
    display: block;
    color: var(--primary-color);
    text-decoration: none;
    margin-bottom: 0.25rem;
    font-size: 1rem;
    transition: color 0.2s;
  }
  .contact:hover {
    color: var(--primary-dark);
  }
  .status {
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: uppercase;
  }
  .status.available {
    background: #dcfce7;
    color: #166534;
  }
  .status.busy {
    background: #fef9c3;
    color: #854d0e;
  }
  .loading, .error, .empty {
    padding: 2rem;
    text-align: center;
    color: #6b7280;
  }
  .error {
    color: var(--error-color);
  }
  .add-first {
    margin-top: 1rem;
    color: var(--primary-color);
    text-decoration: underline;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
  }

  /* Minimal Modal for WorkerForm */
  .modal-overlay {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  .modal-container {
    background: #fff;
    border-radius: 1rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    max-width: 90%;
    width: 500px;
    max-height: 90vh;
    overflow-y: auto;
    padding: 1rem;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  @media (max-width: 640px) {
    .modal-container {
      width: 95%;
      height: 90vh;
      border-radius: 0;
      margin: 0;
    }
  }
</style>
